import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import MediaQuery from "../../../layout/MediaQuery";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import { HybridLink } from "../../../layout/Footer";

const Container = Styled.div`
  width: 100%
  text-align: center;
  margin-bottom: 10rem;
  overflow: hidden;
  border-radius: 10px;

  ${Media.lessThan("medium")`
    margin-bottom: 5rem;
  `}
`;

const ExtendedButton = Styled(Button)`
  font-size: 1.6rem;
  border-radius: 1rem;
  ${Media.lessThan("small")`
    width: 100%;
  `}
`;

const TextContainer = Styled.div`
  text-align: center;
  margin-bottom: 3.5rem;
`;

const ExtendedDescription = Styled(Description)`
  max-width: 88rem;
  margin: 0 auto 3.5rem auto;
`;

const ImageMobile = Styled(Image)`
  width: 100%;
  border-radius: 20px;
`;

const ImageDesktop = Styled(Image)`
  width: 100%;
  border-radius: 20px;
`;

const ExtendedLink = Styled(HybridLink)``;

type AboutFoundersProps = {
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
};

export default ({ imageA, imageB }: AboutFoundersProps) => (
  <Container>
    <TextContainer>
      <Headline>Our Story</Headline>
      <ExtendedDescription>
        The Uizard journey started as a machine learning research project called
        pix2code in 2017 in Copenhagen, Denmark. Uizard only fully formed as a
        company in early 2018 after a trip to San Francisco and a hacking
        session in a Mountain View garage—like a true Silicon Valley cliché.
      </ExtendedDescription>

      <ExtendedLink to="/press_kit.zip">
        <ExtendedButton type="submit" variant="black" solid>
          <MediaQuery lessThan="medium">Download our Press Kit</MediaQuery>
          <MediaQuery greaterThan="medium">
            Download our Press Kit to learn more
          </MediaQuery>
        </ExtendedButton>
      </ExtendedLink>
    </TextContainer>

    <MediaQuery lessThan="670px">
      <ImageMobile
        fluid={"fluid" in imageB ? imageB.fluid : null}
        alt="Picture of the Uizard founders in San Francisco"
      />
    </MediaQuery>

    <MediaQuery greaterThan="670px">
      <ImageDesktop
        fluid={"fluid" in imageA ? imageA.fluid : null}
        alt="Picture of the Uizard founders in San Francisco"
      />
    </MediaQuery>
  </Container>
);
