import * as React from "react";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Layout from "../../components/layout/Layout";
import Content from "../../components/layout/Content/Content";
import Shoutout from "../../components/pages/signup/Shoutout";
import AboutFounders from "../../components/pages/about/AboutFounders";
import AboutCTA from "../../components/pages/about/AboutCTA";
import AboutBanner from "../../components/pages/about/AboutBanner";
import AboutStats from "../../components/pages/about/AboutStats";

const ExtendedContent = Styled(Content)`
  ${Content} {
   ${Media.lessThan("small")`
    margin: 0;
    max-width: 100%;
  `}
  }
`;

const About = ({ data }) => (
  <Layout>
    <Meta
      title="About Uizard | Our Mission To Revolutionize Design | Uizard"
      description="Read about our mission to revolutionize the way we design & build software with the power of modern AI. Help us democratize design & sign up today."
      url="/about/"
    />

    <ExtendedContent>
      <Content narrow>
        <AboutBanner
          headline="Forget no-code, here comes no-design."
          description="Our mission is to democratize design to empower non-designers everywhere to build digital, interactive products."
          imageA={data.bannerImageA.childImageSharp}
          imageTopB={data.bannerTopImageB.childImageSharp}
          imageBottomB={data.bannerBottomImageB.childImageSharp}
        />

        <AboutFounders
          imageA={data.foundersImageA.childImageSharp}
          imageB={data.foundersImageB.childImageSharp}
        />

        <AboutStats
          imageA={data.teamImageA.childImageSharp}
          imageB={data.teamImageB.childImageSharp}
        />

        <AboutCTA />
      </Content>
    </ExtendedContent>

    <Content>
      <Shoutout
        headline="Bring your ideas to life easily"
        description="Design your next project today"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(relativePath: { eq: "about/banner-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2058, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerTopImageB: file(
      relativePath: { eq: "about/banner-top-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 690, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    bannerBottomImageB: file(
      relativePath: { eq: "about/banner-bottom-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 690, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersImageA: file(relativePath: { eq: "about/founders-image-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersImageB: file(relativePath: { eq: "about/founders-image-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    teamImageA: file(relativePath: { eq: "about/team-image-a.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    teamImageB: file(relativePath: { eq: "about/team-image-b.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 718, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
