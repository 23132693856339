import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import { HybridLink } from "../../../layout/Footer";

const Container = Styled.div`
  width: 100%
  text-align: center;
  margin-bottom: 10rem;
  overflow: hidden;
  border-radius: 10px;

  ${Media.lessThan("medium")`
    margin-bottom: 5rem;
  `}
`;

const ExtendedButton = Styled(Button)`
  font-size: 1.6rem;
  border-radius: 1rem;
  ${Media.lessThan("small")`
    width: 100%;
  `}
`;

const TextContainer = Styled.div`
  text-align: center;
  margin-bottom: 3.5rem;
  margin-top: 10rem;
`;

const ExtendedDescription = Styled(Description)`
  max-width: 65rem;
  margin: 0 auto 3.5rem auto;
`;

const ExtendedLink = Styled(HybridLink)``;

export default () => (
  <Container>
    <TextContainer>
      <Headline>Become a uizard!</Headline>
      <ExtendedDescription>
        Want to inspire the no-design movement and empower non-designers around
        the world?
      </ExtendedDescription>

      <ExtendedLink to="/careers/">
        <ExtendedButton type="submit" variant="primarylight" solid>
          See open roles
        </ExtendedButton>
      </ExtendedLink>
    </TextContainer>
  </Container>
);
