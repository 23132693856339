import * as React from "react";
import Styled from "styled-components/macro";
import Image, { GatsbyImageProps } from "gatsby-image";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Tagline from "../../../texts/Tagline";
import MediaQuery from "../../../layout/MediaQuery";

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 29rem 0 23.5rem 0;
  position: relative;
  padding: 0 14rem;
  
   ${Media.lessThan("1024px")`
      margin: 11rem 0 7rem 0;
  `}
  
  ${Media.lessThan("medium")`
      padding: 0 6rem;
  `}
  
  ${Media.lessThan("small")`
      padding: 0;
      margin: 9rem 0 4.5rem 0;
  `}
`;

const Collaborators = Styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const ImageA = Styled(Image)`
  width: 100%;
  margin: 0 auto;
`;

const ImageBannerTopMobile = Styled(Image)`
  max-width: 32.5rem;
  margin: 0 auto 3rem auto;
 
`;

const ImageBannerBottomMobile = Styled(Image)`
  max-width: 32.5rem;
  margin: 3rem auto 0 auto;
`;

const ExtendedDescription = Styled(Description)`
    max-width: 70rem;
    margin: 0 auto;
    
   ${Media.lessThan("large")`
    max-width: 55rem;
  `}
`;

const HeadlineExtended = Styled(Headline)`
  ${Media.greaterThan("1024px")`
    font-size: 5.2rem;
    width: 70%;
    margin: 0 auto 3rem auto;
  `}
`;

const ContainerExtended = Styled(Container)`
  ${Media.lessThan("large")`
    padding: 0;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

type AboutBannerProps = {
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageTopB: GatsbyImageProps;
  imageBottomB: GatsbyImageProps;
};

export default ({
  imageA,
  headline,
  description,
  imageTopB,
  imageBottomB,
}: AboutBannerProps) => (
  <ContainerExtended>
    <MediaQuery lessThan="1024px">
      <ImageBannerTopMobile
        fluid={"fluid" in imageTopB ? imageTopB.fluid : null}
        alt="Uizard team members cursors"
      />
    </MediaQuery>

    <TaglineExtended as="h1">About Uizard</TaglineExtended>
    <HeadlineExtended as="h2">{headline}</HeadlineExtended>

    <ExtendedDescription>{description}</ExtendedDescription>

    <MediaQuery lessThan="1024px">
      <ImageBannerBottomMobile
        fluid={"fluid" in imageBottomB ? imageBottomB.fluid : null}
        alt="Uizard team members cursors"
      />
    </MediaQuery>

    <MediaQuery greaterThan="1024px">
      <Collaborators>
        <ImageA
          fluid={"fluid" in imageA ? imageA.fluid : null}
          alt="Uizard team members cursors"
        />
      </Collaborators>
    </MediaQuery>
  </ContainerExtended>
);
